<template>
<div>
  <div v-for="(u, i) in users" :key="'u_'+i">
    User {{i}}:
    <div v-if="!!$scopedSlots['user-display']">
      Something:
      <slot name="user-display" :user="u">
        N/A
      </slot>
    </div>
    <b-alert v-else>
      Slot scope 'user-display' missing.
    </b-alert>
  </div>
</div>
</template>

<script>
export default {
  props: {
    users: Array,
  }
}
</script>

<style>

</style>