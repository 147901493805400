<template>
<div>
  <!-- Scoped embed -->
  <contact-list :contacts="contacts">
    <template slot-scope="{contact}">
      <div class="table-col">{{contact.id}}</div>
    </template>
  </contact-list>
  <!-- Named slot -->
  <test-slots-slot :users="users">
    <template slot="user-display" slot-scope="{user}">
      <b-button @click="sup(user)">{{ user.name }}</b-button>
    </template>
  </test-slots-slot>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("test-slots");
import TestSlotsSlot from './TestSlotsSlot.vue';
import Vue from 'vue';

let contacts = [
  {
    id: 1,
    name: "Bob",
    info: "Info1"
  },
  {
    id: 2,
    name: "Mary",
    info: "Info2"
  },
  {
    id: 3,
    name: "Joe",
    info: "Info3"
  },
];

Vue.component("contact-list-item",{
  props:["contact"],
  template:`
  <div class="table-row" @click="emitClickEvent">
    <div class="table-col">{{contact.name}}</div>
    <div class="table-col">{{contact.info}}</div>
    <slot></slot>
  </div>
  `,
  methods:{
    emitClickEvent(){
      
    }
  }
});

Vue.component("contact-list",{
  props:["contacts"],
  template:`
    <div class="table">
      <div class="table-header table-row">  
        <div class="table-col">Contact</div>
        <div class="table-col">Info</div>
      </div>
      <div class="table-body">
        <contact-list-item v-for='contact in contacts'
                           :contact="contact"
                           @click="doSomething"
                           :key="contact.id">
          <slot :contact="contact"></slot>
        </contact-list-item>
      </div>
    </div>
  `,
  methods:{
    doSomething(){
      
    }
  }
});

export default {
  components: { TestSlotsSlot },
  data() {
    return {
      users: [
        {name: "yo"},
        {name: "ya"},
        {name: "ye"},
      ],
      contacts,
    }
  },
  methods: {
    sup(user) {
      log.log(`What's up ${user.name}`);
    }
  }
}
</script>

<style>

</style>